.image {
    position: relative;
    margin: 0 auto;
    padding-top: 300px;
    height: 0;
    width: calc(100% - 20px);
    border-radius: 10px;
    overflow: hidden;

    &.editing {
        padding-top: 260px;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    label {
        &.overlay {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;
            cursor: pointer;

            span {
                background: var(--highlight);
                padding: 10px;
                border-radius: 10px;
                color: #333;
            }

            &:hover {
                background-color: var(--highlight);
            }
        }
    }
}