.item-info {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 70px;
    align-items: center;

    input {
        width: 100%;
    }

    a {
        line-height: 1;
        width: fit-content;
        padding: 10px 5px 10px 0;
    }

    p {
        margin: 0;
        padding: 10px 0;
        line-height: 1;
        font-weight: 500;
        text-align: right;
    }
}

&.editing {
    .item-info{
        grid-template-columns: 1fr 80px;
    }
}