.wishlist {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 50px 20px 190px;
    gap: 30px;
    width: 100%;
    max-width: 1100px;
    text-align: left;
    color: white;

    .item {
        display: flex;
        flex-flow: column;
        width: 100%;
        border-radius: 10px;
        background-color: #6a79aa;
        overflow: hidden;

        .wishlist-head {
            display: grid;
            grid-template-columns: 1fr 60px;
            align-items: center;
            background-color: hsl(226, 27%, 44%);

            h3 {
                padding: 10px;
            }

            @import 'fave';
        }

        @import 'info';
        @import 'image';
        @import 'details';

        textarea {
            height: 100px;
            width: 100%;
            resize: none;
        }

        input[type="file"] {
            display: none;
        }

        
    }

    @import 'buttons';
}