.christmas {
    justify-content: start;

    .info {
        display: none;
        font-weight: 300;
        padding: 10px 20px 50px;
        font-size: 130%;


        .secret {
            font-weight: 600;
        }

        .small {
            padding: 20px;
            font-size: 80%;
        }
    }

    &.secret_santa .info {
        display: block;
    }

    .loading {
        display: none;

        &.active {
            display: block;
        }

        circle {
            fill: var(--highlight);
        }
    }
}

@media screen and (max-width:768px) {
    .christmas {
        .wishlist {
            grid-template-columns: 1fr;

            .image {
                padding-top: 200px;
            }
        }
    }
}