.item {
    .action-bar {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        padding: 10px 20px 0 20px;

        span {
            padding: 10px;
        }

        .action-btns {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-flow: wrap;
            margin-left: auto;
            gap: 16px;
            padding: 10px 0;
            z-index: 10;

            .save,
            .cancel {
                display: none;
            }
        }
    }

    &#new .action-bar .action-btns {

        .save,
        .cancel {
            display: block;
        }

        .delete,
        .edit {
            display: none;
        }
    }

    &.editing .action-bar .action-btns {
        .edit {
            display: none;
        }

        .save,
        .cancel {
            display: block;
        }

    }
}