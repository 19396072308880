i svg path {
    fill: var(--highlight);
}

.favourite {
    padding: 10px;
    text-align: center;
    cursor: pointer;

    input {
        display: none;

        &[type="checkbox"]~i svg path {
            fill: #b9b9b9;
            stroke-width: 10%;
            opacity: 0.9;
        }

        &[type="checkbox"]:checked~i svg {
            transition: all 0.6 ease;
            transform: rotateZ(0deg);

            path {
                fill: var(--highlight);
            }
        }
    }

    &:hover {
        input[type="checkbox"]~i svg {
            path {
                opacity: 1;
            }
        }
    }
}