main.error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;

    .gif-wrapper {
        position: relative;
        height: 0;
        width: 40vh;
        padding-top: 40vh;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}