footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    div.dad {
        position: absolute;
        z-index: 3;
        left: 10%;
        bottom: 2vh;
        height: 18vh;
        width: 18vh;
        background: center / contain repeat url(./images/dad.png);
        transition: all 2s ease;
    }

    div.tree {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 32vh;
        height: 50vh;
        padding: 0;
        background: center / contain no-repeat url(./images/tree.png);
        z-index: -2;
    }

    div.snow {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: var(--white);
        background: center / cover no-repeat url(./images/snow.jpg);
        z-index: -3;
    }
}

@media screen and (max-width: 768px) {
    footer {
        div.dad {
            left: 0;
        }
    }
}

@media screen and (min-width: 1000px) and (min-height: 200px) and (max-height: 800px) {
    footer div.dad {
        left: 80%;
    }
}