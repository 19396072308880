:root {
    --white: #fff;
    --highlight: rgb(255, 198, 9);
}

*,
::before,
::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

body {
    font-size: 20px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 300;
}

iframe {
    border: none;
}

h1 {
    font-size: 200%;
    margin-bottom: 15px;
}

a,
a:visited {
    color: var(--white);
    text-decoration: none;
    transition: color 0.3s ease;
}

a {
    color: var(--white);
    
    &.active{
        font-weight: 400;
    }

    &:hover,
    &.active,
    &.active:hover {
        color: var(--highlight);
    }
}

p {
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    background-color: var(--white);
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 100%;
    font-weight: 300;

    &:hover {
        background-color: var(--highlight);
    }

    &:disabled {
        cursor: initial;
        opacity: 0.8;

        &:hover {
            background: #ddd;
        }
    }
}

main {
    min-height: 100%;
    z-index: 2;
    position: relative;
    padding: 180px 10px 20px;
    background: #39497d;
    background: linear-gradient(163deg, rgba(57, 73, 125, 1) 0%, rgba(57, 73, 125, 0.5) 32%, rgba(169, 236, 250, 1) 100%);

    .center {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        text-align: center;
    }
}

.danger {
    background-color: #d24646;
    color: #fff;
}

input,
textarea {
    padding: 8px 10px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 100%;
    font-weight: 300;
}

#buttonDiv {
    z-index: 10;
}

canvas.snow {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@media screen and (max-width: 768px) {
    main {
        padding: 110px 10px 20px;
    }
}