.details {
    margin-top: auto;
    width: 100%;

    &>p {
        padding: 10px 20px 0 20px;
        min-height: 50px;
    }



    .buying {
        display: flex;
        flex-flow: wrap;

        p {
            padding: 10px 20px;
            text-align: center;
            margin: 0 0 0 auto;
        }

        button {
            width: 100%;
            border-radius: 0;

            &.close {
                width: auto;
                margin-left: auto;
                background: none;

                path {
                    transition: fill 0.3s ease;
                }

                &:hover path {
                    fill: var(--highlight);
                }
            }
        }
    }
}