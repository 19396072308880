header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    nav {
        display: grid;
        grid-template-columns: 160px 1fr;
        align-items: center;
        padding: 10px;

        .title {
            padding: 0 10px;
            border-radius: 20px;
            font-family: 'Agbalumo', sans-serif;
            font-size: 110%;
            text-align: center;
            color: var(--highlight);

            &:hover {
                color: var(--white);
            }
        }

        .menu-button {
            display: none;
        }

        .menu {
            display: grid;
            grid-template-columns: 1fr 117px;
            align-items: center;

            a {
                padding: 10px 15px;
            }

            .links {
                text-align: center;
            }

        }

        .profile {
            display: flex;
            align-items: center;
            margin-left: auto;

            button.link {
                padding: 0;
                background: none;
                transition: color 0.3s ease;

                &:hover {
                    color: white;
                }
            }

            .pic {
                display: inline-block;
                position: relative;
                width: 45px;
                height: 45px;
                margin-left: 10px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

    }

    .countdown {
        text-align: center;
        margin-left: auto;
        padding: 10px;
        background-color: var(--highlight);
        opacity: 0.7;
        min-height: 44px;

        span {
            &:nth-of-type(1) {
                font-size: 100%;
                font-weight: bold;
            }

            &:nth-of-type(2) {
                font-size: 80%;
                font-weight: 300;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    header {
        justify-content: center;

        .countdown {
            font-size: 80%;
            display: block;
            text-align: center;

            span:nth-of-type(2) {
                margin-top: 5px;
                display: block;
            }
        }

        nav {
            .menu-button {
                width: 43px;
                padding-left: 20px;
                text-align: center;
                margin-left: auto;
                border-radius: 50%;
                line-height: 1;
                background: none;
                display: grid;
                justify-content: center;
                text-align: center;
                padding-left: 20px;
                z-index: 2001;

                &:hover {
                    background: #333;

                    path {
                        fill: var(--highlight);
                    }
                }

                i {
                    line-height: 0;
                    display: block;
                }
            }

            .title {
                grid-template-columns: 130px 1fr;
            }

            .menu {
                position: fixed;
                top: 0;
                left: 100%;
                width: 100%;
                height: 100%;
                background-color: var(--highlight);
                grid-template-columns: 1fr;
                transition: left 0.3s ease;
                z-index: 200;

                &.active {
                    left: 0;
                }

                .links {
                    display: flex;
                    flex-flow: column;
                    gap: 30px;
                    padding-bottom: 45px;

                    a {
                        color: #333;

                        &:hover {
                            color: var(--white);
                        }

                        &.active {
                            color: #000;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .profile {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 20px;
        }
    }
}